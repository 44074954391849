import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { ImLoop2 } from 'react-icons/im';
import TextIcon from '../../components/textIcon';
import { getHumanDigitalDuration } from '../../init';
import { selectAppStore, useAppSelector } from '../../store';

const UPDATE_INTERVAL = 10 * 60 - 1; // seconds
// const UPDATE_INTERVAL = 10; // seconds

interface IUpdateButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	onUpdate: () => void;
	variant?: string;
	hash?: any;
	updateIntervalSec?: number;
	autoUpdate?: boolean;
	small?: boolean;
	adaptive?: boolean;
}

/**
 * Дуже бажано, щоб onUpdate була мемоізована за допомогою useCallback,
 * інакше будуть виникати масовані оновлення через зміни даної функції
 */
const UpdateButton = ({
	onUpdate,
	variant = 'outline-secondary',
	hash,
	updateIntervalSec = UPDATE_INTERVAL,
	autoUpdate = true,
	small = true,
	adaptive,
	...rest
}: IUpdateButtonProps) => {
	const [updateTime, setUpdateTime] = useState(getNextUpdateTime(updateIntervalSec));
	const { pageIsVisible } = useAppSelector(selectAppStore);
	const [secondsBeforeUpdate, setSecondsBeforeUpdate] = useState(updateIntervalSec);

	const onUpdateClick = useCallback(() => {
		setUpdateTime(getNextUpdateTime(updateIntervalSec));
		onUpdate();
	}, [updateIntervalSec, onUpdate]);

	const tickUpdateTimer = useCallback(() => {
		const newSecondsBeforeUpdate = Math.round((updateTime - new Date().valueOf()) / 1000);
		if (0 < newSecondsBeforeUpdate) {
			setSecondsBeforeUpdate(newSecondsBeforeUpdate);
		} else {
			onUpdateClick();
		}
	}, [updateTime, onUpdateClick]);

	useEffect(() => {
		if (!pageIsVisible) return;

		if (autoUpdate) tickUpdateTimer();
		const interval = autoUpdate ? setInterval(tickUpdateTimer, 1000) : undefined;
		return () => clearInterval(interval);
	}, [autoUpdate, tickUpdateTimer, pageIsVisible]);

	useEffect(() => {
		setUpdateTime(getNextUpdateTime(updateIntervalSec));
	}, [hash, updateIntervalSec]);

	useEffect(() => {
		setSecondsBeforeUpdate(Math.round((updateTime - new Date().valueOf()) / 1000));
	}, [updateTime]);

	return (
		<Button variant={variant} size={small ? 'sm' : undefined} onClick={onUpdateClick} {...rest}>
			<TextIcon Icon={ImLoop2}>
				{autoUpdate ? (
					<span>
						<span className={adaptive ? 'd-none d-xl-inline' : undefined}>Оновлення за </span>
						<>{getHumanDigitalDuration(secondsBeforeUpdate)}</>
					</span>
				) : (
					'Оновити'
				)}
			</TextIcon>
		</Button>
	);
};

export default UpdateButton;

const getNextUpdateTime = (intervalSec: number) => new Date().valueOf() + intervalSec * 1000;
