import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsJournalText } from 'react-icons/bs';

interface ISavedQueryProps extends React.AllHTMLAttributes<HTMLDivElement> {
	term: string;
}
const SavedQuery = ({ title, term }: ISavedQueryProps) => {
	return (
		<Dropdown.Item href="#" data-term={term} className="sq-query">
			<BsJournalText className="me-1" />
			{title}
		</Dropdown.Item>
	);
};

export default SavedQuery;
