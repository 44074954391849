import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BsSearch } from 'react-icons/bs';
import styled from 'styled-components';
import TextIcon from './textIcon';

const SearchWaiter = () => {
	const INTERVAL = 500; // ms
	const [elapsed, setElapsed] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => setElapsed((elapsed) => elapsed + INTERVAL / 1000), INTERVAL);
		return () => clearInterval(interval);
	}, []);

	return (
		<WaiterModal show={0 < elapsed} contentClassName="text-bg-warning" backdrop="static" centered>
			<Modal.Body>
				<h5 className="my-2 d-flex justify-content-center align-items-center">
					<TextIcon Icon={BsSearch} className="animation-bounce me-2" />
					Пошук сюжетів. Зачекайте...
					{elapsed !== 0 && (
						<span className="ms-2 badge rounded-pill bg-body text-body">
							{elapsed.toLocaleString('uk-UA', { minimumFractionDigits: 1 })} сек.
						</span>
					)}
				</h5>
			</Modal.Body>
		</WaiterModal>
	);
};

export default SearchWaiter;

const WaiterModal = styled(Modal)`
	--bs-modal-width: 450px;
`;
