import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import QueryLink from '../../components/queryLink';
import { ITextPart } from '../../init';
import { TNumberWithNull } from '../../interfaces/common';
import { binarySearch, getDifferentNumber, periodCompareFunction } from '../../utils';

interface ITermNavigatorState {
	activePartNo: TNumberWithNull;
	previousPartNo: TNumberWithNull;
	nextPartNo: TNumberWithNull;
}
interface ITermNavigatorProps extends React.AllHTMLAttributes<HTMLDivElement> {
	term: string;
	playedSeconds: number;
	parts?: ITextPart[];
	hashTime?: string;
}
const TermNavigator = ({ term, playedSeconds, parts = [], hashTime }: ITermNavigatorProps) => {
	const [state, setState] = useState<ITermNavigatorState>(() => ({
		activePartNo: null,
		previousPartNo: null,
		nextPartNo: null,
	}));

	useEffect(() => {
		if (!parts) return;
		const { index, previous, next } = binarySearch(parts, playedSeconds, periodCompareFunction);
		if (previous !== state.previousPartNo || next !== state.nextPartNo)
			setState({ activePartNo: index, previousPartNo: previous, nextPartNo: next });
	}, [playedSeconds, parts]); // eslint-disable-line

	return (
		<InputGroup size="sm" className="position-sticky top-0 video-text__navigation">
			<Form.Control
				title={`Пошуковий рядок: ${term}`}
				defaultValue={term}
				readOnly
				className="bg-warning-subtle border-warning"
			/>
			{/* <InputGroup.Text className="px-1 bg-warning-subtle border-warning">
				{state.activePartNo !== null
					? `${state.activePartNo + 1} з ${parts.length}`
					: `${state.previousPartNo !== null ? state.previousPartNo + 1 : ''}...${
							state.nextPartNo !== null ? state.nextPartNo + 1 : ''
					  }`}
			</InputGroup.Text> */}
			{state.previousPartNo !== null && parts[state.previousPartNo] ? (
				<QueryLink
					to={getDifferentNumber(parts[state.previousPartNo].start, hashTime)}
					replace
					className="btn btn-warning px-1"
					title="Попередній збіг"
				>
					<BsChevronUp />
				</QueryLink>
			) : (
				<DisabledLink>
					<BsChevronUp />
				</DisabledLink>
			)}
			{state.nextPartNo !== null && parts[state.nextPartNo] ? (
				<QueryLink
					to={getDifferentNumber(parts[state.nextPartNo].start, hashTime)}
					replace
					className="btn btn-warning px-1"
					title="Наступний збіг"
				>
					<BsChevronDown />
				</QueryLink>
			) : (
				<DisabledLink>
					<BsChevronDown />
				</DisabledLink>
			)}
		</InputGroup>
	);
};

export default TermNavigator;

const DisabledLink = ({ children }: React.AllHTMLAttributes<HTMLButtonElement>) => (
	<Button variant="warning" className="px-1" disabled>
		{children}
	</Button>
);
