import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DotSpinner from '../../components/DotSpinner';
import FetchError from '../../components/FetchError';
import { useDebounce } from '../../hooks';
import { ITextPart, prepareSearchTerm } from '../../init';
import { TStringWithNull } from '../../interfaces/common';
import { moveControlToScreenCenter } from '../../scrolls';
import { useTextByIdQuery } from '../../services/tvApi';
import { selectAppStore, useAppSelector } from '../../store';
import { binarySearch, periodCompareFunction, valueInPeriod } from '../../utils';
import TermNavigator from './TermNavigator';
import VideoTextPart from './videoTextPart';

const WINDOW_HALF_SIZE_WITH_TERM = 50;
const WINDOW_HALF_SIZE_WITHOUT_TERM = 25;
const PLAYED_SECONDS_DEBOUNCE_TIMEOUT_MS = 100;

interface IVideoTextState {
	activeIndex: number; // індекс активної фрази взагалі
	activePartId: TStringWithNull;
	visibleParts?: ITextPart[];
	previousPartId?: TStringWithNull;
	nextPartId?: TStringWithNull;
}
interface IVideoTextProps extends React.AllHTMLAttributes<HTMLDivElement> {
	videoId: string;
	timePresented: boolean;
	term?: string;
	hashTime?: string;
}
const VideoText = ({ videoId, timePresented, term, hashTime }: IVideoTextProps) => {
	const [state, setState] = useState<IVideoTextState>({
		activeIndex: -1,
		activePartId: null,
	});
	const refContainer = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const location = useLocation();
	const { playedSeconds } = useAppSelector(selectAppStore);
	const debouncedPlayedSeconds = useDebounce(playedSeconds, PLAYED_SECONDS_DEBOUNCE_TIMEOUT_MS);
	const [adaptedTerm, setAdaptedTerm] = useState<string>();
	const {
		data: parts,
		error,
		isFetching,
	} = useTextByIdQuery(adaptedTerm !== undefined ? { videoId, params: { term: adaptedTerm } } : skipToken);

	useEffect(() => {
		const isFiltered = (term || '').trim() !== '';
		/**
		 * Тут є один тонкий момент: якщо пошуковий рядок містить оператор "та",
		 * наприклад "Зеленський та Херсон", то загальний текст повідомлення йому задовольняє,
		 * а от частина тексту може не містити разом ці два операнда. В такому випадку
		 * при натисканні на сюжет ми взагалі не побачимо текстовки. Тому тут проводиться
		 * заміна оператора "та" на оператор "або". В даному випадку в текст, що буде показаний
		 * користувачу, попадуть частини сюжету, що містять одне, або інше слово.
		 */
		setAdaptedTerm(
			isFiltered
				? prepareSearchTerm(term)
						.replace(/[|&()+]+/g, '')
						.replace(/\s{2,}/g, ' ')
				: ''
		);
	}, [term]);

	useEffect(() => {
		refContainer.current?.scrollTo({ top: 0, behavior: 'smooth' });
	}, [videoId]);

	useEffect(() => {
		if (!parts) return;
		if (adaptedTerm && !timePresented && parts.length !== 0) {
			navigate(`${parts[0].start}${location.search}`, { replace: true });
		}
	}, [parts]); // eslint-disable-line

	useEffect(() => {
		if (!parts) return;

		// При натисканні на субтитр в playedSeconds передається ціле число, що спричиняє активізацію
		// не потрібного субтитра, а того, що йому передує. Тому адаптуємо playedSelected.
		const adaptedPlayedSeconds = debouncedPlayedSeconds + 1;

		// console.time('parts search');
		// Є дуже велика вірогідність того, що період не змінився. Тут це і перевіряється
		if (
			parts[state.activeIndex] &&
			valueInPeriod(adaptedPlayedSeconds, parts[state.activeIndex].start, parts[state.activeIndex].end)
		)
			return;
		const { index: newActiveIndex, previous, next } = binarySearch(parts, adaptedPlayedSeconds, periodCompareFunction);

		// console.timeEnd('parts search');

		// Без цього рядка не завантажуються тексти для відео, текст в яких починається не з початку
		// if (newActiveIndex === null && state.activeIndex === -1 && parts.length !== 0) newActiveIndex = 0;
		if (
			newActiveIndex === state.activeIndex &&
			parts[previous ?? -1]?.id === state.previousPartId &&
			parts[next ?? -1]?.id === state.nextPartId
		)
			return;

		const middleNo = newActiveIndex ?? previous ?? next;
		const windowHalfSize = term ? WINDOW_HALF_SIZE_WITH_TERM : WINDOW_HALF_SIZE_WITHOUT_TERM;
		const firstNo = Math.max((middleNo ?? 0) - windowHalfSize, 0);
		const lastNo = firstNo + windowHalfSize * 2;
		setState({
			activeIndex: newActiveIndex ?? -1,
			activePartId: newActiveIndex !== null ? parts[newActiveIndex].id : null,
			visibleParts:
				middleNo !== null ? parts.slice(firstNo, lastNo) : state.visibleParts ?? parts.slice(firstNo, lastNo),
			previousPartId: parts[previous ?? -1]?.id,
			nextPartId: parts[next ?? -1]?.id,
		});
	}, [debouncedPlayedSeconds, parts, term]); // eslint-disable-line

	useEffect(() => {
		if (!state.activePartId) return;
		const partElement = document.getElementById(state.activePartId);
		if (!partElement) return;
		moveControlToScreenCenter(partElement, true);
	}, [state.activePartId]);

	if (parts?.length === 0) return null;

	return (
		<VideoTextContainer className="position-relative video-text__container">
			{adaptedTerm && (
				<TermNavigator term={term || ''} playedSeconds={debouncedPlayedSeconds} parts={parts} hashTime={hashTime} />
			)}
			<VideoTextInnerContainer
				className="vstack gap-2 overflow-auto bottom-0 w-100 highlighting-container"
				ref={refContainer}
			>
				{isFetching && <DotSpinner>Завантаження тексту сюжету...</DotSpinner>}
				{error && <FetchError error={error} />}
				{state.visibleParts &&
					state.visibleParts.map((part, index) => (
						<VideoTextPart
							key={part.start}
							part={part}
							active={part.id === state.activePartId}
							youtubeId={videoId}
							hashTime={hashTime}
							// playMarkerPosition={
							// 	state.activePartId === null
							// 		? index === state.previousPartNo
							// 			? 'bottom'
							// 			: index === 0 && state.nextPartNo === 0
							// 			? 'top'
							// 			: undefined
							// 		: undefined
							// }
							playMarkerPosition={
								state.activePartId === null
									? part.id === state.previousPartId
										? 'bottom'
										: !state.previousPartId && part.id === state.nextPartId
										? // : part.id === state.nextPartId
										  'top'
										: undefined
									: undefined
							}
						/>
					))}
			</VideoTextInnerContainer>
		</VideoTextContainer>
	);
};

export default VideoText;

const VideoTextContainer = styled.div`
	flex: 0 0 var(--video-dialog-text-basis);
	@media (max-width: 575px) {
		overflow: auto;
	}
	@media (min-width: 576px) {
		--video-dialog-text-basis: 38vh;
	}
	@media (min-width: 992px) {
		--video-dialog-text-basis: 38%;
	}
	@media (min-width: 1200px) {
		--video-dialog-text-basis: 30%;
	}
`;

const VideoTextInnerContainer = styled.div`
	top: var(--video-text-inner-container_top, 0);
	&:not(:only-child) {
		--video-text-inner-container_top: 39px;
		padding-top: 1px;
	}
	@media (min-width: 576px) {
		position: absolute;
	}
`;
