import { Form } from 'react-bootstrap';
import QuestionButton from '../../components/QuestionButton';
import { useQueryParam } from '../../hooks';

const ShortsCheckbox = () => {
	const { queryParam, setQueryParam } = useQueryParam('shorts');

	const onChange = () => {
		setQueryParam(queryParam === 'on' ? 'off' : 'on');
	};

	return (
		<div className="hstack gap-1">
			<Form.Check
				type="switch"
				id="cbShowShorts"
				label={
					<>
						<span className="d-none d-lg-inline">Показувати відео без тексту</span>
						<span className="d-lg-none">Відео без тексту</span>
					</>
				}
				onChange={onChange}
				checked={queryParam === 'on'}
			/>
			<QuestionButton title="Маються на увазі відео, змістовна текстова частина яких триває менше 20 секунд." />
		</div>
	);
};

export default ShortsCheckbox;
