import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULTS, IYoutubeData, permissionExists, storage, TColorTheme, updateThemeInPage } from '../../init';
import { TStringWithUndefined } from '../../interfaces/common';

export interface IAppSlice {
	userPermissions: string[];
	youtubeData: IYoutubeData;
	playedSeconds: number;
	hasAccess: boolean;
	pageIsVisible: boolean;
	accessToken: TStringWithUndefined;
	colorTheme: TColorTheme;
}
const initialState: IAppSlice = {
	userPermissions: [],
	youtubeData: { id: undefined, time: 0 },
	playedSeconds: 0,
	hasAccess: false,
	pageIsVisible: true,
	accessToken: undefined,
	colorTheme: (storage.get('theme') as TColorTheme) || 'light',
};
export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		changeAccessToken: (state, action: PayloadAction<TStringWithUndefined>) => {
			state.accessToken = action.payload;
		},
		changePermissions: (state, action: PayloadAction<string[] | undefined>) => {
			state.userPermissions = action.payload ? [...action.payload] : [];
			state.hasAccess = permissionExists(DEFAULTS.accessPermissionScope, action.payload);
		},
		changeYoutubeData: (state, action: PayloadAction<IYoutubeData>) => {
			state.youtubeData = action.payload;
			state.playedSeconds = action.payload.time;
		},
		// changeYoutubeId: (state, action: PayloadAction<string | TStringWithUndefined>) => {
		// 	state.youtubeData.id = action.payload;
		// 	state.youtubeData.time = 0;
		// 	state.playedSeconds = 0;
		// },
		// changeYoutubeTime: (state, action: PayloadAction<number>) => {
		// 	state.youtubeData.time = action.payload;
		// 	state.playedSeconds = action.payload;
		// },
		changePlayedSeconds: (state, action: PayloadAction<number>) => {
			state.playedSeconds = action.payload;
		},
		changePageIsVisible: (state, action: PayloadAction<boolean>) => {
			state.pageIsVisible = action.payload;
		},
		changeColorTheme: (state, action: PayloadAction<TColorTheme>) => {
			state.colorTheme = action.payload;
			storage.set({ theme: action.payload });
			updateThemeInPage();
		},
	},
});

export const {
	changeAccessToken,
	changeYoutubeData,
	changePlayedSeconds,
	changePageIsVisible,
	changePermissions,
	changeColorTheme,
} = appSlice.actions;

export default appSlice.reducer;
