import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { currentYMD, getSearchParamsObject, objectsAreNotEqual, prepareSearchParamsObject } from './init';
import { DEFAULT_QUERY_PARAMS, IQueryParams, USER_CUSTOM_PERIOD_INDEX } from './interfaces/params';

const DEFAULT_DEBOUNCE_DELAY = 250;

export const useQueryParam = (key: keyof IQueryParams, delay = DEFAULT_DEBOUNCE_DELAY) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [queryParam, setQueryParam] = useState(searchParams.get(key) || DEFAULT_QUERY_PARAMS[key]);
	const debouncedValue = useDebounce(queryParam, delay);

	useEffect(() => {
		const newParam = searchParams.get(key) || DEFAULT_QUERY_PARAMS[key];
		if (newParam !== queryParam) setQueryParam(newParam);
	}, [searchParams, key]); // eslint-disable-line

	useEffect(() => {
		if (debouncedValue === undefined || debouncedValue === DEFAULT_QUERY_PARAMS[key]) searchParams.delete(key);
		else searchParams.set(key, debouncedValue);
		// if (key === 'sort_index') searchParams.delete('shorts');
		// if (key === 'term' && !debouncedValue?.trim()) searchParams.delete('shorts');
		setSearchParams(searchParams);
	}, [debouncedValue, key]); // eslint-disable-line

	const onQueryParamChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setQueryParam(event.target.value);
	}, []);

	return { queryParam, setQueryParam, searchParams, onQueryParamChange };
};

export const useMultipleQueryParams = (delay = DEFAULT_DEBOUNCE_DELAY) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [params, setParams] = useState(getSearchParamsObject(searchParams));
	const debouncedParams = useDebounce(params, delay);

	useEffect(() => {
		const newParams = getSearchParamsObject(searchParams);
		if (objectsAreNotEqual(newParams, params)) setParams(newParams);
	}, [searchParams]); // eslint-disable-line

	useEffect(() => {
		if (!objectsAreNotEqual(getSearchParamsObject(searchParams), debouncedParams)) return;
		setSearchParams(new URLSearchParams(prepareSearchParamsObject({ ...debouncedParams })));
	}, [debouncedParams]); // eslint-disable-line

	const onParamChange = useCallback(
		(key: keyof IQueryParams) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
			const newParams = {
				...params,
				[key]: event.target.value,
			};
			if (key === 'period_index')
				newParams.start_date = newParams.end_date =
					event.target.value === USER_CUSTOM_PERIOD_INDEX ? currentYMD() : undefined;
			setParams(newParams);
		},
		[params]
	);

	return { params, onParamChange };
};

export const useDebounce = <T>(value: T, delay = DEFAULT_DEBOUNCE_DELAY) => {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);

			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay] // Only re-call effect if value or delay changes
	);

	return debouncedValue;
};

export const useScreenWidth = (width: number) => {
	const [isSmaller, setIsSmaller] = useState(window.innerWidth < width);

	useEffect(() => {
		function handleResize() {
			setIsSmaller(window.innerWidth < width);
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [width]);

	return [isSmaller];
};
