import { Container, Nav, Navbar } from 'react-bootstrap';
import { BsQuestionCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SearchForm from '../features/search/searchForm';
import SearcherIcon from '../img/search-app.webp';
import { DEFAULTS } from '../init';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

interface INavHeaderProps extends React.AllHTMLAttributes<HTMLDivElement> {
	isAuthenticated: boolean;
	isLoading: boolean;
	hasAccess: boolean;
}
const NavHeader = ({ isAuthenticated, isLoading, hasAccess }: INavHeaderProps) => {
	return (
		<Navbar bg="primary" variant="dark" expand="sm" fixed="top">
			<Container fluid={DEFAULTS.fluid as any}>
				<Link to="/" className="navbar-brand">
					<BrandImage src={process.env.PUBLIC_URL + '/favicon.png'} className="d-inline-block" />
					{DEFAULTS.pageTitle}
				</Link>
				<Navbar.Toggle area-controls="navbarSupportedContent" />
				{hasAccess && (
					<Navbar.Collapse id="navbarSupportedContent" className="gap-3">
						<NavButtons>
							<Nav.Link
								href={`https://magna.${process.env.REACT_APP_DOMAIN_NAME}/`}
								target="_blank"
								className="hstack gap-1"
							>
								<NavImage src={SearcherIcon} />
								MAGNA
							</Nav.Link>
							<Nav.Link href="https://fsmgp.gitbook.io/telek/" target="_blank" className="text-nowrap">
								<BsQuestionCircle className="icon-lg mt-n1" />
								<span className="d-sm-none d-md-inline ms-2">Довідка</span>
							</Nav.Link>
						</NavButtons>
						<SearchForm />
					</Navbar.Collapse>
				)}
				<Nav className="ms-auto">{isLoading || isAuthenticated ? <LogoutButton /> : <LoginButton />}</Nav>
			</Container>
		</Navbar>
	);
};

export default NavHeader;

const NavButtons = styled(Nav)`
	margin: 2px -0.5rem -2px 0;
`;

const BrandImage = styled.img`
	width: 40px;
	height: 40px;
	margin: -14px 0.25rem -2px 0;
	filter: drop-shadow(0 0 1px white);
`;

const NavImage = styled.img`
	width: auto;
	height: 24px;
	margin-top: -2px;
	margin-left: -2px;
`;
