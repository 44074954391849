import { Container } from 'react-bootstrap';
import SearchResults from '../features/search/searchResults';

// interface IWorkplaceProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const WorkPlace = () => {
	return (
		<Container fluid>
			{/* <ParamsContainer className="mb-3" /> */}
			<SearchResults />
		</Container>
	);
};

export default WorkPlace;
