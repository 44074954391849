import classNames from 'classnames';
import { Col, Row, RowProps } from 'react-bootstrap';
import { useMultipleQueryParams, useScreenWidth } from '../../hooks';
import { USER_CUSTOM_PERIOD_INDEX } from '../../interfaces/params';
import SearchForm from '../search/searchForm';
import Period from './Period';
import Severity from './Severity';
import Sorting from './Sorting';

interface IParamsContainerProps extends RowProps {}
const ParamsContainer = ({ className, ...rest }: IParamsContainerProps) => {
	const [isSmall] = useScreenWidth(576);
	const {
		params: { period_index, start_date, end_date },
		onParamChange,
	} = useMultipleQueryParams();

	const isDatePeriod = period_index === USER_CUSTOM_PERIOD_INDEX;

	return (
		<Row {...rest} className={classNames('gx-3 gy-1 justify-content-center', className)}>
			{isSmall && (
				<Col xs={12}>
					<SearchForm />
				</Col>
			)}
			<Col sm={6} lg={isDatePeriod ? 6 : 4} xl={isDatePeriod ? 3 : 4}>
				<Sorting />
			</Col>
			<Col sm={6} lg={isDatePeriod ? 6 : 4} xl={isDatePeriod ? 3 : 4}>
				<Severity />
			</Col>
			<Col xs lg={isDatePeriod ? 12 : 4} xl={isDatePeriod ? 6 : 4}>
				<Period period_index={period_index} start_date={start_date} end_date={end_date} onParamChange={onParamChange} />
			</Col>
		</Row>
	);
};

export default ParamsContainer;
