import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import { selectAppStore, useAppSelector } from '../store';
import EmptyWorkPlace from './EmptyWorkPlace';
import PageTitle from './PageTitle';
import NavFooter from './navFooter';
import NavHeader from './navHeader';
import { TopScroller } from './topScroller';
import YoutubePlayer from './youtubePlayer';

const Layout = () => {
	const { isAuthenticated, isLoading } = useAuth0();
	const { hasAccess, colorTheme } = useAppSelector(selectAppStore);
	return (
		<>
			<PageTitle />
			<NavHeader hasAccess={hasAccess} isAuthenticated={isAuthenticated} isLoading={isLoading} />
			{hasAccess ? <Outlet /> : <EmptyWorkPlace />}
			<NavFooter hasAccess={hasAccess} colorTheme={colorTheme} />
			<YoutubePlayer />
			<TopScroller />
		</>
	);
};

export default Layout;
