import styled from 'styled-components';

interface IClampedText {
	linesCount: number;
	lineHeight?: number;
}
export const ClampedText = styled.div<IClampedText>`
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.linesCount};
	/* line-clamp: 2; */
	-webkit-box-orient: vertical;
	overflow: hidden;
	line-height: ${({ lineHeight = 1.4 }) => lineHeight + 'em'};
	max-height: ${({ lineHeight = 1.4, linesCount }) => lineHeight * linesCount + 'em'};
`;
