import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import PageTitle from '../../components/PageTitle';
import { IViewChannel } from '../../interfaces/channels';

interface IChannelListItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	channel: IViewChannel;
	active?: boolean;
}
const ChannelListItem = ({ channel, active = false }: IChannelListItemProps) => {
	const {
		channel: { TITLE, LOGO },
		count,
		max_date,
		min_date,
		youtubeId,
	} = channel;
	return (
		<ChannelButton
			type="button"
			className={classNames(
				'list-group-item list-group-item-action d-flex gap-2 align-items-center',
				active && 'active position-sticky top-bottom-sticky'
			)}
			data-channel-id={youtubeId}
		>
			{active && <PageTitle title={TITLE} />}
			{LOGO ? (
				<img src={LOGO} alt={TITLE} className="flex-shrink-0 channel-image white-halo" />
			) : (
				<Skeleton className="channel-image" />
			)}
			<div className="min-width-0">
				<div className="text-truncate" title={TITLE}>
					{TITLE || <Skeleton />}
				</div>
				{(min_date || max_date) && (
					<div
						className="text-truncate opacity-75"
						title={max_date ? 'Дата останнього повідомлення' : 'Дата першого повідомлення'}
					>
						{dayjs(max_date || min_date).format('L LT')}
						{/* {dayjs(lastDate).fromNow()} */}
					</div>
				)}
			</div>
			<span className="badge rounded-pill ms-auto">{count}</span>
		</ChannelButton>
	);
};

export default ChannelListItem;

const ChannelButton = styled.button`
	--bs-list-group-active-bg: var(--selected-bg-color);
	--bs-list-group-active-border-color: var(--selected-bg-color);
	.badge {
		background-color: var(--list-badge-bg-color, var(--bs-secondary));
	}
	&.active {
		.badge {
			--list-badge-bg-color: #5c3210;
			--list-badge-bg-color: color-mix(in oklch, var(--selected-bg-color) 30%, black 30%);
		}
	}
`;
