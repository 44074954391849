import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BsArrowLeft, BsArrowRight, BsTags } from 'react-icons/bs';
import QuestionButton from '../../components/QuestionButton';
import TextIcon from '../../components/textIcon';
import { useQueryParam } from '../../hooks';
import { SEVERITY_VISIBILITY_STATUS, TSeverityVisibilityStatus } from '../../interfaces/params';

// interface ISeverityProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const Severity = () => {
	const { queryParam, onQueryParamChange, searchParams } = useQueryParam('severity');
	const [severityStatus, setSeverityStatus] = useState<TSeverityVisibilityStatus>('D');
	const term = searchParams.get('term') || '';

	useEffect(() => {
		// const sortIndex = searchParams.get('sort_index') ||;
		if (/\s+(?:and|і|и|та|or|или|або)\s+/.test(term)) return setSeverityStatus('N');
		if (/\?|\*/.test(term)) return setSeverityStatus('0');
		// if (sort_index !== DEFAULT_SEARCH_PARAMS.sort_index) return setSeverityStatus('1');
		setSeverityStatus('D');
	}, [term]);

	return (
		<Form.Group>
			<Form.Label htmlFor="search_severity" className="mb-0 d-flex justify-content-between">
				<TextIcon Icon={BsTags}>Строгість</TextIcon>
				<QuestionButton title="Пошук з&nbsp;низькою строгістю дає&nbsp;більше результатів, але&nbsp;вони&nbsp;є менш релевантними. Тому використовуйте&nbsp;його у&nbsp;випадках, коли пошук з&nbsp;високою строгістю не&nbsp;дає результатів." />
			</Form.Label>
			<input
				id="search_severity"
				type="range"
				name="severity"
				className="form-range mb-0"
				min="0"
				max="100"
				step="25"
				autoComplete="off"
				value={SEVERITY_VISIBILITY_STATUS[severityStatus].value || queryParam}
				onChange={onQueryParamChange}
				disabled={severityStatus !== 'D'}
			/>
			<Form.Text className="d-flex justify-content-between align-items-center text-muted mt-n2">
				{/* <div className="d-flex justify-content-between align-items-center small-text text-muted mt-n2"> */}
				<TextIcon Icon={BsArrowLeft} className="my-n1">
					Нижча
				</TextIcon>
				<span className={classNames(severityStatus !== 'D' && 'text-danger', 'd-inline-flex align-items-center gap-1')}>
					<span title="Поточна значення">{SEVERITY_VISIBILITY_STATUS[severityStatus].value || queryParam} %</span>
					{/* <span>Поточна: {SEVERITY_VISIBILITY_STATUS[severityStatus].value || queryParam} %</span> */}
					{severityStatus !== 'D' && (
						<QuestionButton title={SEVERITY_VISIBILITY_STATUS[severityStatus].hint} className="mt-n1" />
					)}
				</span>
				<TextIcon Icon={BsArrowRight} iconLast className="my-n1">
					Вища
				</TextIcon>
				{/* </div> */}
			</Form.Text>
		</Form.Group>
	);
};

export default Severity;
