import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import duration from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Storage } from './classes/storage';
import { TObjectType, TStringWithUndefined } from './interfaces/common';
import { DEFAULT_QUERY_PARAMS, IQueryParams } from './interfaces/params';

dayjs.locale('uk');
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);

export interface IYoutubeData {
	id: TStringWithUndefined;
	time: number;
}

// LOGO: 'https://via.placeholder.com/100x100',
// LOGO: 'https://api.lorem.space/image?w=100&h=100',

export interface IVideo {
	ID: number;
	CHANNEL_ID: number;
	PUBLISHED_AT: string;
	YOUTUBE_ID: string;
	TITLE: string;
	DESCRIPTION: string;
	LOGO: string;
	LANGUAGE: string;
	PROCESSED: boolean;
	DURATION: number;
	CHANNEL_YID?: string;
	hl?: string[];
	real_duration?: number;
}

export interface ISearchParams {
	term?: string;
	hitDate?: string;
}

export interface IPlayerProgressData {
	playedSeconds: number;
	played: number;
	loadedSeconds: number;
	loaded: number;
}
export type TPlayerProgressFunction = (playedSeconds: number) => void;

export type THighlighting = { [key: string]: { body: string[] } };
// export type TFacetValue = number | string;
// export interface IFacetCounts {
// 	facet_fields: {
// 		country?: TFacetValue[];
// 		source_id?: TFacetValue[];
// 	};
// 	facet_ranges: {
// 		hit_date?: {
// 			counts: TFacetValue[];
// 		};
// 	};
// }
export interface ITextPartInterval {
	start: number;
	end: number;
}
export interface ITextPart extends ITextPartInterval {
	id: string;
	content: string;
}
export interface ISearchResponse {
	responseHeader: {
		partialResults?: boolean;
		QTime: number;
	};
	response: {
		numFound: number;
		maxScore: number;
		docs: ITextPart[];
	};
	// facet_counts: IFacetCounts;
	highlighting?: THighlighting;
}

export const DEFAULTS = {
	pageTitle: 'Телек',
	fluid: true,
	searchParams: {
		term: '',
		// hitDate: dayjs().subtract(2, 'days').format('YYYY-MM-DD'), // for debug
		hitDate: dayjs().format('YYYY-MM-DD'),
	} as ISearchParams,
	availableScopes: 'read:tv',
	accessPermissionScope: 'read:tv',
	locale: 'uk-UA',
	videosPerPage: 36,
};

export interface IAuth0AccessToken {
	scope: string;
	iat: number;
	exp: number;
}

export const getTokenSilentlyAuthOptions = (
	scope = DEFAULTS.accessPermissionScope,
	withUserData = false
): GetTokenSilentlyOptions => {
	return {
		authorizationParams: {
			audience:
				process.env.NODE_ENV === 'production'
					? (process.env.REACT_APP_PROD_AUTH0_AUDIENCE as string)
					: (process.env.REACT_APP_DEV_AUTH0_AUDIENCE as string),
			scope: scope + (withUserData ? ' profile email offline_access' : ''),
		},
	};
};

/**
 * It gets the value of a search parameter from the URL.
 * @param {URLSearchParams} searchParams - the URLSearchParams object that contains the search
 * parameters
 * @param paramName - the name of the parameter to get from the searchParams
 */
export const getSearchParam = (searchParams: URLSearchParams, paramName: keyof ISearchParams) =>
	searchParams.get(paramName) || DEFAULTS.searchParams[paramName] || '';

/**
 * Given a searchParams object, remove any key-value pairs where the value is the default value
 * @param params - The search parameters object that you want to prepare.
 * @returns The searchParamsObject is being returned with the default values removed.
 */
export const prepareSearchParamsObject = (params: Partial<IQueryParams>) => {
	Object.keys(params).forEach((key) => {
		if (params[key as keyof IQueryParams] === DEFAULT_QUERY_PARAMS[key as keyof IQueryParams])
			delete params[key as keyof IQueryParams];
	});
	return params;
};

export const searchParamsObjectToString = (searchParamsObject: TObjectType): string => {
	const sp = new URLSearchParams();
	for (const key in searchParamsObject) {
		sp.append(key, searchParamsObject[key]);
	}
	const result = sp.toString();
	return result ? `?${result}` : '';
};

/**
 * It takes a URLSearchParams object and returns an object with the same properties.
 * @param {URLSearchParams} searchParams - The URLSearchParams object that is passed to the function.
 * @returns An object with the search parameters.
 */
export const getSearchParamsObject = (searchParams: URLSearchParams) => {
	const params: IQueryParams = { ...DEFAULT_QUERY_PARAMS };
	searchParams.forEach((value, key) => {
		// здесь value это строка!
		if (!value) return;
		params[key as keyof IQueryParams] = value;
	});
	return params;
};

/**
 * It takes a date string and returns a pair of date strings.
 * @param {string | undefined} strDate - The date to get the daily report for.
 * @returns An array of two strings, representing the start and end dates for the daily report.
 */
export const getDailyParams = (strDate: string | undefined) => {
	let start_date = dayjs(strDate, 'YYYY-MM-DD');
	if (!start_date.isValid()) start_date = dayjs().startOf('day');
	const end_date = start_date.isSame(dayjs(), 'day') ? dayjs() : start_date.add(1, 'day');
	return [start_date.toISOString(), end_date.toISOString()];
};

/**
 * It takes a number of seconds and returns a human readable duration
 * @param {number} seconds - number - The number of seconds to convert to a human readable format.
 * @returns A string
 */
export const getHumanDigitalDuration = (seconds: number) => {
	if (seconds === Infinity) return '0:00';
	const duration = dayjs.duration(seconds, 's');
	return duration.format(duration.hours() === 0 ? 'm:ss' : 'H:mm:ss');
};

/**
 * It translates the following:
 * @param {string} [term] - The term to translate.
 */
export const prepareSearchTerm = (term: string = '') => {
	const replaceFunc = (match: string, group1: string) =>
		`"${group1.replace(/\s+(and|і|и|та|or|или|або)\s+/g, ' __$1__ ')}"`;
	// const replaceFunc = (match: string, group1: string) => {
	// 	const replacedGroup1 = group1.replace(/\s+(and|і|и|та|or|или|або)\s+/g, ' __$1__ ');
	// 	return `"${replacedGroup1}"`;
	// };

	return term
		.trim()
		.replace(/:/g, '\\:')
		.replace(/"([^"]+)"/g, replaceFunc)
		.replace(/\s+(?:and|і|и|та)\s+/gi, ' && ')
		.replace(/\s+(?:or|или|або)\s+/gi, ' || ')
		.replace(/__(and|і|и|та|or|или|або)__/gi, '$1');
	// .replace(/("[^"]+)\s+(and|і|и|та|or|или|або)\s+([^"]+")/gi, '$1 __$2__ $3')
};
// export const prepareSearchTerm = (term: string = '') =>
// 	term
// 		.trim()
// 		.replace(/:/g, '\\:')
// 		.replace(/("[^"]+)\s+(and|і|и|та|or|или|або)\s+([^"]+")/gi, '$1 __$2__ $3')
// 		.replace(/\s+(?:and|і|и|та)\s+/gi, ' && ')
// 		.replace(/\s+(?:or|или|або)\s+/gi, ' || ')
// 		.replace(/__(and|і|и|та|or|или|або)__/gi, '$1');

export const promiseTimeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * It checks if a permission exists in a list of permissions
 * @param {string} permissionForCheck - The permission you want to check for.
 * @param {string[]} permissions - The permissions that the user has.
 */
export const permissionExists = (permissionForCheck: string, permissions: string[] = []) =>
	permissions.includes(permissionForCheck);

/**
 * Определяет, изменились ли значения полей объекта target по сравнению с объектом source.
 * Если источника нет, то считаем, что объекты различаются
 * @param source Исходный объект
 * @param target Сравниваемый объект
 */
export const objectsAreNotEqual = (source: TObjectType | undefined, target: TObjectType) =>
	source === undefined || [...Object.keys(source), ...Object.keys(target)].some((key) => source[key] !== target[key]);

/**
 * Dates
 */

export const YYYYMMDD = 'YYYY-MM-DD';

export const currentYMD = () => dayjs().format(YYYYMMDD);

export const periodIsValid = (startDate: TStringWithUndefined | null, endDate: TStringWithUndefined | null) => {
	if (startDate === endDate) return true;
	if (!startDate || !endDate) return false;
	return dayjs(startDate, YYYYMMDD).isSameOrBefore(dayjs(endDate, YYYYMMDD), 'day');
};

export const getGMTForDate = (isoDate?: string) => {
	const localOffsetInMinutes = (isoDate ? new Date(isoDate) : new Date()).getTimezoneOffset();
	const gmtOffsetHours = Math.floor(Math.abs(localOffsetInMinutes) / 60);
	const gmtOffsetMinutes = Math.abs(localOffsetInMinutes) % 60;
	const sign = -localOffsetInMinutes >= 0 ? '+' : '-';
	const hours = gmtOffsetHours.toString().padStart(2, '0');
	const minutes = gmtOffsetMinutes.toString().padStart(2, '0');
	return `GMT${sign}${hours}:${minutes}`;
};

export const USER_GMT = getGMTForDate();

/**
 * This function takes a string date and returns it with a time of 00:00:00 appended to it.
 * @param {string} strDate - The parameter `date` is a string representing a date in format YYYY-MM-DD. It
 * is used as input to the function `getLocaleStrDate`.
 */
export const getLocalStrDate = (strDate?: string | null) => (strDate || currentYMD()) + 'T00:00:00';

export type TColorTheme = 'light' | 'dark';

/**
 * Storage
 */

interface ITvStorage {
	theme: TColorTheme;
}
export const storage = new Storage<ITvStorage>('tv');

export const updateThemeInPage = () => {
	document.documentElement.dataset.bsTheme = storage.get('theme') || 'light';
};
