import classNames from 'classnames';
import React from 'react';

interface ICountryFlagProps extends React.AllHTMLAttributes<HTMLDivElement> {
	country?: string;
	isSquare?: boolean;
}
const CountryFlag = React.memo(({ country = 'un', title, isSquare = false, className, ...rest }: ICountryFlagProps) => {
	let c = country.toLowerCase();
	if (c === 'uk') c = 'ua';

	return (
		<span
			className={classNames(
				className,
				'flex-shrink-0 country-flag shadow-country-flag',
				isSquare && 'fis',
				`fi fi-${c}`
			)}
			title={title || country}
			{...rest}
		></span>
	);
});

export default CountryFlag;
