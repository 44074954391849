import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISearchSlice {
	pageNo: number;
	/**
	 * Використовується для можливості виконання пошуку з тим самим пошуковим рядком
	 */
	searchTs: number;
}
const initialState: ISearchSlice = {
	pageNo: 0,
	searchTs: new Date().valueOf(),
};
export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		changePageNo: (state, action: PayloadAction<number>) => {
			state.pageNo = action.payload;
		},
		changeSearchTs: (state) => {
			state.searchTs = new Date().valueOf();
		},
	},
});

export const { changePageNo, changeSearchTs } = searchSlice.actions;

export default searchSlice.reducer;
