import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BsCalendarRange } from 'react-icons/bs';
import SelectOptions from '../../components/SelectOptions';
import TextIcon from '../../components/textIcon';
import { currentYMD, periodIsValid } from '../../init';
import { IQueryParams, PERIOD_OPTIONS, USER_CUSTOM_PERIOD_INDEX } from '../../interfaces/params';

interface IPeriodProps {
	period_index: string;
	start_date?: string;
	end_date?: string;
	onParamChange: (key: keyof IQueryParams) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}
const Period = ({ period_index, start_date, end_date, onParamChange }: IPeriodProps) => {
	const [isPeriodValid, setIsPeriodValid] = useState(() => periodIsValid(start_date, end_date));

	useEffect(() => {
		setIsPeriodValid(periodIsValid(start_date, end_date));
	}, [start_date, end_date]);

	return (
		<div className="hstack gap-2 position-relative">
			<Form.Group controlId="ePeriodIndex" className="flex-grow-1">
				<Form.Label className="mb-0 text-nowrap">
					<TextIcon Icon={BsCalendarRange} className="mt-n1" inline>
						Дата публікації
					</TextIcon>
				</Form.Label>
				<Form.Select
					name="period_index"
					autoComplete="off"
					value={period_index}
					onChange={onParamChange('period_index')}
				>
					<SelectOptions options={PERIOD_OPTIONS} />
				</Form.Select>
			</Form.Group>
			{period_index === USER_CUSTOM_PERIOD_INDEX && (
				<>
					<Form.Group controlId="eStartDate" className={isPeriodValid ? undefined : 'is-not-valid'}>
						<Form.Label className="mb-0">з</Form.Label>
						<Form.Control
							type="date"
							title="З"
							name="start_date"
							autoComplete="off"
							min="2021-07-16"
							max={currentYMD()}
							value={start_date || ''}
							onChange={onParamChange('start_date') as any}
						/>
					</Form.Group>
					<Form.Group controlId="eEndDate" className={isPeriodValid ? undefined : 'is-not-valid'}>
						<Form.Label className="mb-0">
							по <span className="text-small">(включно)</span>
						</Form.Label>
						<Form.Control
							type="date"
							title="По (включно)"
							name="end_date"
							autoComplete="off"
							min="2021-07-16"
							max={currentYMD()}
							value={end_date || ''}
							onChange={onParamChange('end_date') as any}
						/>
					</Form.Group>
				</>
			)}
			{!isPeriodValid && (
				<Form.Text className="position-absolute text-danger top-100 end-0">
					Кінцева дата не може бути меншою за початкову
				</Form.Text>
			)}
		</div>
	);
};

export default Period;
