import classNames from 'classnames';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { BsMicMute, BsYoutube } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import HumanReadableTime from '../../components/HumanReadableTime';
import CountryFlag from '../../components/countryFlag';
import QueryLink from '../../components/queryLink';
import TextIcon from '../../components/textIcon';
import { IVideo, getHumanDigitalDuration } from '../../init';
import { CHANNEL_SKELETON, IChannel } from '../../interfaces/channels';
import { ClampedText } from '../../styledComponents';

interface IVideoItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	video: IVideo;
	channel?: IChannel;
	no: number;
}
const VideoItem = ({ video, channel = CHANNEL_SKELETON, no }: IVideoItemProps) => {
	const { TITLE, LOGO, YOUTUBE_ID, PROCESSED, PUBLISHED_AT, LANGUAGE, DURATION, CHANNEL_YID, hl, real_duration } =
		video;

	const channelLink = `https://www.youtube.com/channel/${CHANNEL_YID || channel.YOUTUBE_ID}`;

	const duration = DURATION || real_duration || 0;
	return (
		<Card
			className={classNames('shadow-sm videos__card', !PROCESSED && !duration && 'videos__card_not-processed')}
			data-youtube-id={YOUTUBE_ID}
		>
			<VideoImage src={LOGO} to={video.YOUTUBE_ID} className="rounded-top">
				<div className="videos__no badge panel-on-image position-absolute bottom-left-2" title="Номер">
					{no}
				</div>
				<div className="position-absolute bottom-right-2">
					{duration !== 0 ? (
						<span className="videos__duration badge panel-on-image" title="Тривалість">
							{getHumanDigitalDuration(duration)}
						</span>
					) : (
						<OnAirIcon Icon={BsMicMute} className="text-warning" title="Відео скоріше за все не містить тексту" />
					)}
				</div>
			</VideoImage>
			<Card.Body className="d-flex gap-3">
				<div className="d-flex flex-column justify-content-between align-items-center">
					{channel.LOGO ? (
						<a href={channelLink} target="_blank" rel="noreferrer" title="Відкрити канал в YouTube">
							<img src={channel.LOGO} alt={channel.TITLE} className="channel-image" />
						</a>
					) : (
						<Skeleton className="channel-image" />
					)}
					<a
						href={`https://www.youtube.com/watch?v=${YOUTUBE_ID}`}
						className="btn btn-link p-0 text-decoration-none lh-1"
						rel="noreferrer"
						target="_blank"
						title="Відкрити сюжет в YouTube"
					>
						<TextIcon Icon={BsYoutube} className="icon-lg text-danger" />
					</a>
				</div>
				<div className="d-flex flex-column gap-1 lh-sm w-100 min-width-0">
					<QueryLink to={video.YOUTUBE_ID} className="text-decoration-none">
						<ClampedText linesCount={2} lineHeight={1.25} className="card-title h6 m-0" title={TITLE}>
							{TITLE}
						</ClampedText>
					</QueryLink>
					<div className="small-text">
						<div className="d-flex justify-content-between gap-1">
							{channel.TITLE ? (
								<a
									href={channelLink}
									target="_blank"
									rel="noreferrer"
									title="Відкрити канал в YouTube"
									className="text-truncate"
								>
									{channel.TITLE}
								</a>
							) : (
								<Skeleton containerClassName="w-50" />
							)}
							<CountryFlag country={LANGUAGE} />
						</div>
						<HumanReadableTime isoDate={PUBLISHED_AT} />
					</div>
				</div>
			</Card.Body>
			{hl && hl.length !== 0 && (
				<div className="small-text videos__text highlighting-container mx-3 mb-3">
					{hl.map((text, index) => (
						<p key={index} dangerouslySetInnerHTML={{ __html: `&hellip;${text}&hellip;` }} />
					))}
				</div>
			)}
		</Card>
	);
};

export default VideoItem;

export const OnAirIcon = styled(TextIcon)`
	font-size: 1.5rem;
	filter: drop-shadow(0 -1px 1px black);
`;

interface IVideoImageProps {
	src: string;
}
const VideoImage = styled(QueryLink)<IVideoImageProps>`
	position: relative;
	padding-top: 56%; /* Player ratio: 100 / (1280 / 720) */
	overflow: hidden;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: ${({ src }) => `rgba(var(--bs-secondary-bg-rgb), 1) url(${src})`};
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
`;
