import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import appReducer from './features/app/appSlice';
import searchReducer from './features/search/searchSlice';
import { tvApi } from './services/tvApi';

const store = configureStore({
	reducer: {
		app: appReducer,
		search: searchReducer,
		[tvApi.reducerPath]: tvApi.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(tvApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

export const selectAppStore = (state: RootState) => state.app;
// export const selectSearchStore = (state: RootState) => state.search;

export default store;
