import { skipToken } from '@reduxjs/toolkit/query';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import HumanReadableTime from '../../components/HumanReadableTime';
import PageTitle from '../../components/PageTitle';
import WithChannels, { IWithChannels } from '../../components/hoc/withChannels';
import { useQueryParam } from '../../hooks';
import { useVideoInfoQuery } from '../../services/tvApi';
import { changeYoutubeData } from '../app/appSlice';
import VideoText from './videoText';

interface ISelectedVideoProps extends IWithChannels, React.AllHTMLAttributes<HTMLDivElement> {}
const SelectedVideo = ({ channels }: ISelectedVideoProps) => {
	const { queryParam: term } = useQueryParam('term');
	const { videoId, time } = useParams<'videoId' | 'time'>();
	const [show, setShow] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();
	const { data: video } = useVideoInfoQuery(videoId || skipToken);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(changeYoutubeData({ id: videoId, time: parseInt(time || '0') }));
	}, [videoId, time, dispatch]);

	// useEffect(() => {
	// 	if (!videoId || 768 <= window.innerWidth) return;
	// 	setTimeout(scrollWindowToTop, 0);
	// }, [videoId]);

	const onHide = async () => {
		dispatch(changeYoutubeData({ id: undefined, time: 0 }));
		setShow(false);
		setTimeout(navigate, 200, '/video' + location.search);
	};

	if (!videoId) return null;

	const channel = video ? channels?.entities[video.CHANNEL_ID] : undefined;
	const published = video ? video.PUBLISHED_AT : undefined;

	return (
		<>
			{video && <PageTitle title={video.TITLE} />}
			<VideoModal
				show={show}
				fullscreen="sm-down"
				centered
				onHide={onHide}
				size="xl"
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					{channel?.LOGO ? (
						<img src={channel.LOGO} alt={channel.TITLE} className="channel-image me-2 my-n2" />
					) : (
						<Skeleton className="channel-image me-2 my-n2" />
					)}
					<Modal.Title className="w-100 min-width-0 fs-5">
						<div className="d-block d-lg-none text-truncate">{video?.TITLE || <Skeleton className="w-75" />}</div>
						<div className="d-none d-lg-block">{channel?.TITLE || <Skeleton className="w-25" />}</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex gap-3 flex-column flex-lg-row">
					<div className="vstack flex-grow-0 flex-md-grow-1 gap-2">
						<div id="reactPlayerContainer"></div>
						<h4 className="d-none d-lg-block m-0">{video ? video.TITLE : <Skeleton className="w-75" />}</h4>
						<div className="mt-auto d-none d-lg-block">
							{published ? <HumanReadableTime isoDate={published} /> : <Skeleton className="w-25" />}
						</div>
					</div>
					<VideoText videoId={videoId} timePresented={!!time} term={term} hashTime={time} />
				</Modal.Body>
			</VideoModal>
		</>
	);
};

export default WithChannels(SelectedVideo);

const VideoModal = styled(Modal)`
	&:not(:has(.video-text__container)) {
		@media (min-width: 1200px) {
			.modal-xl {
				--bs-modal-width: 800px;
			}
		}
	}
`;
