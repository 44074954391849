import dayjs from 'dayjs';
import { getLocalStrDate } from '../init';
import { ISelectOption, TStringWithUndefined } from './common';

export interface IQueryParams {
	term: string;
	severity: string;
	period_index: string;
	start_date: TStringWithUndefined;
	end_date: TStringWithUndefined;
	sort_index: string;
	/**
	 * Selected channel's YoutubeId
	 */
	channel: TStringWithUndefined;
	shorts: string;
}
export const DEFAULT_QUERY_PARAMS: IQueryParams = {
	term: '',
	severity: '75',
	period_index: '1',
	start_date: undefined,
	end_date: undefined,
	sort_index: '0',
	channel: undefined,
	shorts: 'on',
};

export type TMessageListOrder = 'D' | 'R';
interface ISortSelectOption extends ISelectOption {
	messageOrder: TMessageListOrder;
}
export enum SORT_INDEX {
	score = 0,
	published_asc,
	published_desc,
}
export const SORT_OPTIONS: ISortSelectOption[] = [
	{
		label: 'за релевантністю (за наявності пошукового рядка)',
		value: 'score desc, published_at asc, id asc',
		messageOrder: 'D',
	},
	{
		label: 'в хронологічному порядку',
		value: 'published_at asc, id asc',
		messageOrder: 'D',
	},
	{
		label: 'в зворотному хронологічному порядку',
		value: 'published_at desc, id asc',
		messageOrder: 'R',
	},
];

export interface IPeriodOption {
	label: string;
	start: () => string;
	// end?: string;
	// gap?: string;
}
/**
 * Тут майже скрізь використовується dayjs, а не можливості Solr,
 * інакше виникають складнощі в разі попадання в обраний діапазон
 * дати переводу годинника.
 */
export const PERIOD_OPTIONS: IPeriodOption[] = [
	{
		label: 'будь-коли',
		start: () => '',
		// gap: '+1YEAR',
	},
	{
		label: 'сьогодні',
		start: () => 'NOW/DAY',
		// gap: '+1HOUR',
	},
	{
		label: 'поточного тижня',
		start: () => dayjs().startOf('week').toISOString(),
	},
	{
		label: 'за останні 7 днів',
		start: () => dayjs().subtract(7, 'days').startOf('day').toISOString(),
	},
	{
		label: 'поточного місяця',
		// start: 'NOW/MONTH' + HIT_DATE_OFFSET,
		start: () => dayjs().startOf('month').toISOString(),
	},
	{
		label: 'за останні 30 днів',
		// start: 'NOW-1MONTH/DAY' + HIT_DATE_OFFSET,
		start: () => dayjs().subtract(30, 'days').startOf('day').toISOString(),
	},
	{
		label: 'за останні 90 днів',
		// start: 'NOW-3MONTH/DAY',
		start: () => dayjs().subtract(90, 'days').startOf('day').toISOString(),
		// gap: '+7DAY',
	},
	{
		label: 'поточного року',
		// start: 'NOW/YEAR',
		start: () => dayjs().startOf('year').toISOString(),
		// gap: '+1MONTH',
	},
	{
		label: 'між вказаними датами',
		start: () => '',
	},
];
export const USER_CUSTOM_PERIOD_INDEX = (PERIOD_OPTIONS.length - 1).toString();

interface ISeverityStatus {
	value?: string;
	hint?: string;
}
export type TSeverityVisibilityStatus = 'D' | 'N' | '0';
export const SEVERITY_VISIBILITY_STATUS: { [key in TSeverityVisibilityStatus]: ISeverityStatus } = {
	D: {},
	N: {
		value: 'н/д',
		hint: 'При використанні логічних операторів параметр "строгість" не може бути застосований (див. довідку)',
	},
	// '1': {
	// 	value: '100',
	// 	hint: 'Застосована максимальна строгість у зв’язку з тим, обрано сортування не за релевантністю (див. довідку).',
	// },
	'0': {
		value: '0',
		hint: 'Застосована мінімальна строгість у зв’язку з тим, що в пошуковому рядку використано символи підстановки (* або ?)',
	},
};

export const getPeriodDates = (
	period_index: string,
	start_date: TStringWithUndefined,
	end_date: TStringWithUndefined,
	defaultEndDate: string = 'NOW'
) => {
	const periodIndex = parseInt(period_index, 10);
	return period_index !== USER_CUSTOM_PERIOD_INDEX
		? [PERIOD_OPTIONS[periodIndex].start(), defaultEndDate]
		: [
				new Date(getLocalStrDate(start_date)).toISOString(),
				`${new Date(getLocalStrDate(end_date)).toISOString()}+1DAY`,
		  ];
};
