import classNames from 'classnames';
import React from 'react';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import styled from 'styled-components';
import QueryLink from '../../components/queryLink';
import TextIcon from '../../components/textIcon';
import { getHumanDigitalDuration, ITextPart } from '../../init';
import { TStringWithUndefined } from '../../interfaces/common';
import { getDifferentNumber } from '../../utils';
import PlayMarker from './PlayMarker';

interface IVideoTextPartProps extends React.AllHTMLAttributes<HTMLDivElement> {
	part: ITextPart;
	active: boolean;
	youtubeId: TStringWithUndefined;
	hashTime?: string;
	playMarkerPosition?: 'top' | 'bottom';
}
const VideoTextPart = ({
	part: { start, end, content, id },
	active,
	youtubeId,
	hashTime,
	playMarkerPosition,
}: IVideoTextPartProps) => {
	return (
		<>
			{playMarkerPosition === 'top' && <PlayMarker />}
			<TextRow
				className={classNames(
					'd-flex justify-content-between align-items-start gap-2',
					active && 'is-active',
					playMarkerPosition && 'position-relative'
				)}
			>
				<QueryLink
					to={getDifferentNumber(start, hashTime)}
					replace
					className={classNames(
						'text-decoration-none lh-sm fs-14 hstack gap-2 align-items-start',
						!active ? 'link-primary' : 'link-danger'
					)}
				>
					<TextTime className={classNames('text-nowrap hl-time', !active ? 'bg-primary-subtle' : 'bg-danger-subtle')}>
						{getHumanDigitalDuration(start)}
					</TextTime>
					<TextContent id={id} className="text-break" dangerouslySetInnerHTML={{ __html: content }} />
				</QueryLink>
				<ExternalLink
					href={`https://www.youtube.com/watch?v=${youtubeId}&t=${Math.floor(start)}`}
					className="link-secondary"
					target="_blank"
					rel="noreferrer"
				>
					<TextIcon Icon={BsBoxArrowUpRight} />
				</ExternalLink>
				{/* {playMarkerPosition && <PlayMarker playMarkerPosition={playMarkerPosition} />} */}
			</TextRow>
			{playMarkerPosition === 'bottom' && <PlayMarker />}
		</>
	);
};

export default VideoTextPart;

const ExternalLink = styled.a`
	margin-top: -0.38em;
`;

const TextRow = styled.div`
	/* margin-right: 5px; */
	--hl-text-color: var(--bs-secondary-color);
	&.is-active {
		--hl-text-color: var(--bs-emphasis-color);
	}
`;

const TextTime = styled.span`
	padding: 2px 5px 0;
	font-weight: 500;
	border-radius: 4px;
`;

const TextContent = styled.span`
	color: var(--hl-text-color);
`;
