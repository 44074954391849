import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import ReactPlayer from 'react-player/youtube';
import { ConnectedProps, connect } from 'react-redux';
import styled from 'styled-components';
import { changePlayedSeconds } from '../features/app/appSlice';
// import { changePlayedSeconds } from '../features/app/appSlice';
import { IPlayerProgressData } from '../init';
import { RootState } from '../store';

interface IYoutubePlayerProps extends PropsFromRedux, React.HTMLAttributes<HTMLDivElement> {}
const YoutubePlayer = ({ changePlayedSeconds, youtubeData, className, ...rest }: IYoutubePlayerProps) => {
	const [ready, setReady] = useState(false);
	const refPlayer = useRef<ReactPlayer>();

	const onProgress = (state: IPlayerProgressData) => {
		if (state.played !== 0 && state.loaded !== 0) changePlayedSeconds(state.playedSeconds);
	};

	const onReady = () => {
		refPlayer.current?.seekTo(youtubeData.time, 'seconds');
		setReady(true);
	};

	useEffect(() => {
		refPlayer.current?.seekTo(youtubeData.time, 'seconds');
	}, [youtubeData.time]);

	const portalContainer = document.getElementById('reactPlayerContainer');
	return createPortal(
		<div className={classNames(className, (!portalContainer || youtubeData.id === undefined) && 'd-none')} {...rest}>
			<PlayerWrapper>
				<Player
					// url={`https://youtu.be/${youtubeData.id}?t=${youtubeData.time}`}
					// url={`https://www.youtube.com/watch?v=${youtubeData.id}?t=${youtubeData.time}`}
					url={`https://youtu.be/${youtubeData.id}`}
					playing={ready && youtubeData.id !== undefined}
					controls
					width="100%"
					height="100%"
					onProgress={onProgress}
					onReady={onReady}
					ref={refPlayer}
					config={{
						youtube: {
							playerVars: { showinfo: 1, hl: 'uk', modestbranding: 0 },
						},
					}}
				/>
			</PlayerWrapper>
		</div>,
		portalContainer || document.body
	);
};

const PlayerWrapper = styled.div`
	position: relative;
	padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

const Player = styled(ReactPlayer)`
	position: absolute;
	top: 0;
	left: 0;
`;

const mapState = (state: RootState) => ({
	youtubeData: state.app.youtubeData,
});

const mapDispatch = { changePlayedSeconds };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(YoutubePlayer);
