import { skipToken } from '@reduxjs/toolkit/query';
import { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { BsFilm } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import { Outlet, useSearchParams } from 'react-router-dom';
import FetchError from '../../components/FetchError';
import PageTitle from '../../components/PageTitle';
import Caption from '../../components/caption';
import WithChannels, { IWithChannels } from '../../components/hoc/withChannels';
import VideoPagination from '../../components/pagination';
import SearchWaiter from '../../components/searchWaiter';
import TextIcon from '../../components/textIcon';
import ImgIntro from '../../img/undraw_solution_mindset_34bi.svg';
import ImgVoid from '../../img/undraw_void_3ggu.svg';
import {
	DEFAULTS,
	USER_GMT,
	getSearchParam,
	getSearchParamsObject,
	objectsAreNotEqual,
	periodIsValid,
	prepareSearchTerm,
} from '../../init';
import {
	DEFAULT_QUERY_PARAMS,
	IQueryParams,
	PERIOD_OPTIONS,
	SORT_INDEX,
	SORT_OPTIONS,
	getPeriodDates,
} from '../../interfaces/params';
import { scrollWindowToTop } from '../../scrolls';
import {
	ISearchVideoParams,
	TFacetSearchVideoParams,
	useGetVideosFacetQuery,
	useGetVideosQuery,
} from '../../services/tvApi';
import { RootState } from '../../store';
import ParamsContainer from '../params/ParamsContainer';
import ShortsCheckbox from '../params/Shorts';
import ChannelList from '../video/channelList';
import VideoList from '../video/videoList';
import { changePageNo } from './searchSlice';
import UpdateButton from './updateButton';

interface ISearchResultsProps extends IWithChannels, PropsFromRedux {}
const SearchResults = ({ searchState: { pageNo, searchTs }, changePageNo, channels }: ISearchResultsProps) => {
	const [searchParams] = useSearchParams();

	const [queryParams, setQueryParams] = useState<ISearchVideoParams>();
	const {
		data,
		error: videosError,
		isFetching: videosIsFetching,
		refetch: refetchVideos,
	} = useGetVideosQuery(queryParams || skipToken);

	const [facetQueryParams, setFacetQueryParams] = useState<TFacetSearchVideoParams>();
	const {
		data: facets,
		error: facetError,
		refetch: refetchFacet,
	} = useGetVideosFacetQuery(facetQueryParams || skipToken);
	// console.log(facet);

	useEffect(() => {
		if (data?.pageNo === 0) changePageNo(data.pageNo);
	}, [data, changePageNo]);

	useEffect(() => {
		scrollWindowToTop();

		const params = getSearchParamsObject(searchParams);
		if (!periodIsValid(params.start_date, params.end_date)) return;

		const newQueryParams = getTranslatedParams(params);
		if (objectsAreNotEqual(queryParams, newQueryParams)) {
			setQueryParams(newQueryParams);
			const { pageNo, sort, channel, ...rest } = newQueryParams;
			setFacetQueryParams(rest);
		} else {
			refetchVideos();
			refetchFacet();
		}
	}, [searchParams, searchTs]); // eslint-disable-line

	useEffect(() => {
		if (!queryParams) return;
		if (pageNo === queryParams.pageNo) return;
		setQueryParams({ ...queryParams, pageNo });
		setTimeout(scrollWindowToTop, 0);
	}, [pageNo]); // eslint-disable-line

	const updateVideos = useCallback(() => {
		if (!queryParams) return;
		if (queryParams.pageNo === 0) {
			refetchVideos();
		} else {
			setQueryParams({ ...queryParams, pageNo: 0 });
		}
		refetchFacet();
	}, [refetchVideos, refetchFacet, queryParams]);

	const SearchTitle = <PageTitle title={`«${getSearchParam(searchParams, 'term')}»`} />;

	if (!data)
		return (
			<>
				<ParamsContainer />
				<Caption
					imgSrc={ImgIntro}
					title="Введіть пошукову фразу та знайдіть відео!"
					level={4}
					className="text-primary position-fixed top-50 start-50 translate-middle text-center"
				/>
				{videosIsFetching && <SearchWaiter />}
			</>
		);

	// const channelListVisible = facets?.length !== 0 || searchParams.get('channel');
	const channelListVisible = facets?.length !== 0 || queryParams?.channel;
	return (
		<>
			{SearchTitle}
			<Row>
				{channelListVisible && (
					<Col md={4} lg={3} xxl={2} className="order-2 order-md-1 mb-3">
						{facetError && <FetchError error={facetError} />}
						<ChannelList channels={channels} searchFacets={facets} />
					</Col>
				)}
				<Col md={8} lg={9} xxl={10} className="order-1 order-md-2 flex-grow-1">
					<ParamsContainer />
					{videosError && <FetchError error={videosError} />}
					{data && (
						<>
							<div className="d-flex justify-content-between align-items-center mb-1 gap-1">
								<div className="h6 text-muted m-0 text-nowrap" title={`Час пошуку: ${data.QTime} мс`}>
									<TextIcon Icon={BsFilm}>
										<span className="d-none d-md-inline">Знайдено сюжетів</span>
										<span className="d-md-none">Сюжетів</span>: {data.total}
									</TextIcon>
								</div>
								<ShortsCheckbox />
								<UpdateButton
									onUpdate={updateVideos}
									hash={`${JSON.stringify(queryParams)}_${searchTs}`}
									autoUpdate={data.pageNo === 0}
									adaptive
								/>
							</div>
							{channels && (
								<VideoList
									videos={data.videos}
									totalCount={data.total}
									startNo={data.pageNo * DEFAULTS.videosPerPage}
									channels={channels.entities}
									mlo={SORT_OPTIONS[queryParams?.sortIndex || SORT_INDEX.score].messageOrder}
								/>
							)}
							{data.total === 0 && (
								<Caption
									imgSrc={ImgVoid}
									level={4}
									title="За Вашим запитом відео не знайдено"
									className="text-info text-center"
								/>
							)}
							<VideoPagination
								activePageNo={data.pageNo}
								pageCount={Math.ceil(data.total / DEFAULTS.videosPerPage)}
								onPageClick={changePageNo}
							/>
						</>
					)}
				</Col>
			</Row>
			<Outlet />
			{videosIsFetching && <SearchWaiter />}
		</>
	);
};

const mapState = (state: RootState) => ({
	searchState: state.search,
});

const mapDispatch = {
	changePageNo,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WithChannels(SearchResults));

const getTranslatedParams = ({
	term,
	severity,
	period_index,
	start_date,
	end_date,
	sort_index,
	channel,
	shorts,
}: IQueryParams) => {
	const searchTerm = prepareSearchTerm(term) || '*';

	let sortIndex = parseInt(sort_index, 10);
	if (SORT_OPTIONS.length <= sortIndex) sortIndex = parseInt(DEFAULT_QUERY_PARAMS.sort_index);
	if (searchTerm === '*' && sortIndex === SORT_INDEX.score) sortIndex = SORT_INDEX.published_desc;

	let periodIndex = parseInt(period_index, 10);
	if (PERIOD_OPTIONS.length <= periodIndex) periodIndex = parseInt(DEFAULT_QUERY_PARAMS.period_index);

	const translatedParams: ISearchVideoParams = {
		TZ: USER_GMT,
		term: searchTerm,
		severity: `${severity}%`,
		sortIndex,
		// sortIndex буде вказувати на дійсне значення, бо він перевіряється вище
		sort: SORT_OPTIONS[sortIndex].value as string,
		pageNo: 0, // Номер сторінки вводимо тут, тому що ця функція викликається виключна при новому пошуку
		channel,
		// shorts,
	};
	if (period_index !== '0')
		translatedParams.period = `[${getPeriodDates(period_index, start_date, end_date).join(' TO ')}}`;
	// if (sortIndex === SORT_INDEX.score && shorts === 'on') translatedParams.shorts = shorts;
	if (shorts === 'on') translatedParams.shorts = shorts;
	return translatedParams;
};

// type TTranslatedParams = ReturnType<typeof getTranslatedParams>;
