import React from 'react';
import { IAllChannels } from '../../interfaces/channels';
import { useChannelsQuery } from '../../services/tvApi';

export interface IWithChannels {
	channels?: IAllChannels;
}

const WithChannels =
	<T extends IWithChannels>(WrappedComponent: React.ComponentType<T>) =>
	(props: Omit<T, keyof IWithChannels>) => {
		// eslint-disable-next-line
		const { data: channels } = useChannelsQuery(undefined, {
			refetchOnMountOrArgChange: false,
		});

		return <WrappedComponent {...(props as T)} channels={channels} />;
	};

export default WithChannels;
