import React from 'react';
import { IVideo } from '../../init';
import { TChannels } from '../../interfaces/channels';
import { TMessageListOrder } from '../../interfaces/params';
import VideoItem from './videoItem';

interface IVideoListProps extends React.AllHTMLAttributes<HTMLDivElement> {
	videos: IVideo[];
	totalCount: number;
	startNo: number;
	channels: TChannels;
	mlo: TMessageListOrder;
}
const VideoList = ({ videos, channels, totalCount, startNo, mlo }: IVideoListProps) => {
	if (!channels) return <></>;

	return (
		<div className="videos__list mb-3 gap-4">
			{videos.map((video, index) => (
				<VideoItem
					key={video.YOUTUBE_ID}
					video={video}
					channel={channels[video.CHANNEL_ID]}
					no={mlo === 'D' ? startNo + index + 1 : totalCount - (startNo + index)}
				/>
			))}
		</div>
	);
};

export default VideoList;
