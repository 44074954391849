import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';
import { moveControlToScreenCenter } from '../../scrolls';

/**
 * Тут трохи заскладний процес, як для такого простого компонента,
 * але завдяки цьому інтерфейс не сіпається.
 */

// interface IPlayMarkerProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const PlayMarker = () => {
	const [show, setShow] = useState(false);
	const refProgress = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const tm = setTimeout(() => {
			setShow(true);
		}, 200);
		return () => clearTimeout(tm);
	}, []);

	useEffect(() => {
		if (show) moveControlToScreenCenter(refProgress.current);
	}, [show]);

	return (
		<PlayMarkerContainer
			variant="danger"
			className={classNames('opacity-75', !show && 'd-none')}
			animated
			now={100}
			label="Ви тут. Текст між збігами приховано!"
			ref={refProgress}
		/>
	);
};

export default PlayMarker;

const PlayMarkerContainer = styled(ProgressBar)`
	min-height: 1em;
`;
