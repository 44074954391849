import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Navbar, NavDropdown } from 'react-bootstrap';
import { BsCheckCircle } from 'react-icons/bs';
import { FaSignOutAlt } from 'react-icons/fa';
import styled from 'styled-components';
import DotSpinner from './DotSpinner';
import ThemeButton from './ThemeButton';

const LogoutButton = () => {
	const { isLoading, logout, isAuthenticated, user } = useAuth0();

	const onLogoutClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		const { origin, pathname } = window.location;
		logout({ logoutParams: { returnTo: `${origin}${pathname}` } });
	};

	if (isLoading)
		return (
			<Navbar.Text className="ms-3">
				<DotSpinner variant="light" size="sm">
					Вхід...
				</DotSpinner>
			</Navbar.Text>
		);

	if (!isAuthenticated || !user) return null;

	return (
		<NavDropdown
			title={
				<UserPanel bg={user.picture || ''}>
					<span className="logout__user-name d-inline-block">{user.name}</span>
				</UserPanel>
			}
			id="basic-nav-dropdown"
			align="end"
			className="text-nowrap logout__container"
		>
			<NavDropdown.Header>
				{user.email}
				{user.email_verified && <BsCheckCircle className="text-success ms-1" />}
			</NavDropdown.Header>
			<NavDropdown.Divider />
			<ThemeButton />
			<NavDropdown.Item as="button" onClick={onLogoutClick}>
				<FaSignOutAlt className="me-1" />
				Вихід
			</NavDropdown.Item>
		</NavDropdown>
	);
};

export default LogoutButton;

const IMAGE_SIZE = 40;
interface IUserPanel {
	bg: string;
}
const UserPanel = styled.span<IUserPanel>`
	position: relative;
	margin-left: ${IMAGE_SIZE + 15}px;
	&::before {
		content: '';
		position: absolute;
		top: -11px;
		right: calc(100% + 0.5rem);
		// border: 1px solid var(--primary-light-text);
		box-shadow: 0 0 4px rgba(255, 255, 255, 1);
		width: ${IMAGE_SIZE}px;
		height: ${IMAGE_SIZE}px;
		background-image: ${(props) => `url(${props.bg})`};
		background-size: cover;
		border-radius: 50%;
	}
`;
