import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';

interface IPageRange {
	pages: number[];
	showToFirst: boolean;
	showToLast: boolean;
}
const PAGE_DELTA = 3;

interface VideoIPaginationProps extends React.AllHTMLAttributes<HTMLDivElement> {
	pageCount: number;
	activePageNo: number;
	onPageClick?: (newPageNo: number) => void;
}
const VideoPagination = ({ pageCount, activePageNo, onPageClick, className }: VideoIPaginationProps) => {
	const [pageRange, setPageRange] = useState<IPageRange>({ pages: [], showToFirst: false, showToLast: false });

	useEffect(() => {
		let firstVisiblePage = activePageNo - PAGE_DELTA;
		const showToFirst = 0 < firstVisiblePage;
		if (!showToFirst) firstVisiblePage = 0;

		let lastVisiblePage = activePageNo + PAGE_DELTA;
		const showToLast = lastVisiblePage < pageCount - 1;
		if (!showToLast) lastVisiblePage = pageCount - 1;

		setPageRange({
			showToFirst,
			showToLast,
			pages: new Array(lastVisiblePage - firstVisiblePage + 1)
				.fill(true)
				.map((dummy, index) => index + firstVisiblePage),
		});
	}, [activePageNo, pageCount]);

	const onPage = (event: React.MouseEvent<HTMLAnchorElement>) => {
		const { pageNo } = ((event.target as HTMLElement).closest('.page-link') as HTMLAnchorElement).dataset;
		if (!pageNo) return;
		event.preventDefault();
		event.stopPropagation();
		onPageClick && onPageClick(parseInt(pageNo, 10));
	};

	if (pageCount < 2) return null;

	return (
		<Pagination className={classNames(className, 'justify-content-center')}>
			{pageRange.showToFirst && (
				<>
					<Pagination.First data-page-no={0} onClick={onPage} />
					<Pagination.Ellipsis />
				</>
			)}
			{pageRange.pages.map((index) => (
				<Pagination.Item key={index} active={index === activePageNo} data-page-no={index} onClick={onPage}>
					{index + 1}
				</Pagination.Item>
			))}
			{pageRange.showToLast && (
				<>
					<Pagination.Ellipsis />
					<Pagination.Last data-page-no={pageCount - 1} onClick={onPage} />
				</>
			)}
		</Pagination>
	);
};

export default VideoPagination;
