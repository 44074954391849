import classNames from 'classnames';
import React from 'react';
import { IconType } from 'react-icons';
import { BsQuestionCircle } from 'react-icons/bs';
import { showAlert } from '../alerts';

interface IQuestionButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	Icon?: IconType;
}
const QuestionButton = ({ title, className, Icon = BsQuestionCircle, ...rest }: IQuestionButtonProps) => {
	const onQuestionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		const { content } = (event.currentTarget as HTMLButtonElement).dataset;
		showAlert(content || 'Справка по даному елементу наразі не доступна.', 'Довідка');
	};

	return (
		<button
			className={classNames('btn btn-link btn-sm p-0 text-secondary', className)}
			data-content={title}
			{...rest}
			onClick={onQuestionClick}
		>
			<Icon />
		</button>
	);
};

export default QuestionButton;
