import React, { useEffect, useState } from 'react';
import { useQueryParam } from '../../hooks';
import { CHANNEL_SKELETON, IAllChannels, IViewChannel } from '../../interfaces/channels';
import { TFacetSearchVideoResults } from '../../services/tvApi';
import ChannelListItem from './channelListItem';

interface IChannelListProps extends React.AllHTMLAttributes<HTMLDivElement> {
	channels?: IAllChannels;
	searchFacets?: TFacetSearchVideoResults;
}
const ChannelList = ({ channels, searchFacets }: IChannelListProps) => {
	const [items, setItems] = useState<IViewChannel[]>([]);
	const { queryParam: activeChannelId, setQueryParam: setActiveChannelId } = useQueryParam('channel');

	useEffect(() => {
		if (!searchFacets || !channels) return;
		const { entities, youtubeMapping } = channels;
		const newItems = searchFacets
			.map((f) => {
				const channel = entities[youtubeMapping[f.youtubeId] || -1];
				if (!channel) return undefined;
				return {
					count: f.count,
					max_date: f.max_date,
					min_date: f.min_date,
					channel: channel || { ...CHANNEL_SKELETON },
					youtubeId: f.youtubeId,
				} as IViewChannel;
			})
			.filter(Boolean) as IViewChannel[];
		/**
		 * Можливий варіант, коли користувач обрав канал, потім змінив умови пошуку і з'ясувалось, що
		 * в обраному канали сюжети відсутні. Для того, щоб користувач розумів, що його результати
		 * обмежені обраним каналом, виводимо його пусту плашку також.
		 */
		if (activeChannelId) {
			const activeChannelInList = newItems.find(({ youtubeId }) => youtubeId === activeChannelId);
			if (!activeChannelInList)
				newItems.unshift({
					count: 0,
					channel: channels.entities[channels.youtubeMapping[activeChannelId] || -1] || { ...CHANNEL_SKELETON },
					youtubeId: activeChannelId,
				});
		}
		setItems(newItems as IViewChannel[]);
	}, [channels, searchFacets, activeChannelId]);

	if (!searchFacets) return null;

	const onChannelClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const { channelId } = ((event.target as HTMLElement).closest('button') as HTMLButtonElement).dataset;
		if (!channelId) return;
		const clickedChannelId = channelId;
		setActiveChannelId(clickedChannelId !== activeChannelId ? clickedChannelId : undefined);
	};

	return (
		<div className="list-group top-bottom-sticky" onClick={onChannelClick}>
			{items.map((channel) => (
				<ChannelListItem key={channel.youtubeId} channel={channel} active={channel.youtubeId === activeChannelId} />
			))}
		</div>
	);
};

export default ChannelList;
