import { Form } from 'react-bootstrap';
import { BsSortDown } from 'react-icons/bs';
import SelectOptions from '../../components/SelectOptions';
import TextIcon from '../../components/textIcon';
import { useQueryParam } from '../../hooks';
import { SORT_OPTIONS } from '../../interfaces/params';

// interface ISortingProps {}
const Sorting = () => {
	const { queryParam, onQueryParamChange } = useQueryParam('sort_index');
	return (
		<Form.Group controlId="eSortIndex">
			<Form.Label className="mb-0">
				<TextIcon Icon={BsSortDown}>Сортування</TextIcon>
			</Form.Label>
			<Form.Select autoComplete="off" value={queryParam} onChange={onQueryParamChange} name="sort_index">
				<SelectOptions options={SORT_OPTIONS} />
			</Form.Select>
		</Form.Group>
	);
};

export default Sorting;
