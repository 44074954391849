import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ITextPart, IVideo } from '../init';
import { IAllChannels } from '../interfaces/channels';
import { TStringWithUndefined } from '../interfaces/common';
import { RootState } from '../store';

interface ITextByIdParams {
	videoId: string;
	params: { term: string };
}

export interface ISearchVideoBaseParams {
	TZ: string;
	term: string;
	severity: string;
	sort: string;
	sortIndex: number;
	period?: string;
	shorts?: TStringWithUndefined;
}
export interface ISearchVideoParams extends ISearchVideoBaseParams {
	pageNo: number;
	channel: TStringWithUndefined;
}
interface ISearchVideoResult {
	videos: IVideo[];
	total: number;
	QTime: number;
	pageNo: number;
}
export type TFacetSearchVideoParams = Omit<ISearchVideoBaseParams, 'sort'>;
export type TFacetSearchVideoResults = {
	max_date?: string | undefined;
	min_date?: string | undefined;
	count: number;
	youtubeId: string;
}[];

export const tvApi = createApi({
	reducerPath: 'tvApi',
	baseQuery: fetchBaseQuery({
		baseUrl: 'api/',
		prepareHeaders: (headers, { getState }) => {
			const accessToken = (getState() as RootState).app.accessToken;
			if (accessToken) {
				headers.set('Authorization', `Bearer ${accessToken}`);
			}
			return headers;
		},
	}),
	// tagTypes: [QUERY_TAGS.rubric, QUERY_TAGS.source],
	refetchOnMountOrArgChange: 300,
	endpoints: (builder) => ({
		textById: builder.query<ITextPart[], ITextByIdParams>({
			query: ({ videoId, params }) => ({
				url: `text/${videoId}`,
				method: 'POST',
				body: params,
			}),
		}),
		channels: builder.query<IAllChannels, void>({
			query: () => `allChannels`,
		}),
		// channelsByDate: builder.query<TChannels, string>({
		// 	query: (date) => {
		// 		const [start, end] = getDailyParams(date);
		// 		const params = new URLSearchParams({ start, end });
		// 		return `channels?${params.toString()}`;
		// 	},
		// }),
		videoInfo: builder.query<IVideo | undefined, string>({
			query: (youtubeId) => `video/${youtubeId}`,
		}),
		// videos: builder.query<IVideo[], string>({
		// 	query: (params) => `videos?${params}`,
		// }),
		// Тут використовується POST, бо рядок параметрів може буде досить довгим через term
		getVideos: builder.query<ISearchVideoResult, ISearchVideoParams>({
			query: (params) => ({
				url: 'videos',
				method: 'POST',
				// ! Кількість сюжетів на сторінку не передаємо, але цей параметр має бути узгоджений з сервером
				body: params,
				// body: { ...params, perPage: DEFAULTS.videosPerPage },
			}),
		}),
		getVideosFacet: builder.query<TFacetSearchVideoResults, TFacetSearchVideoParams>({
			query: (params) => ({
				url: 'videosFacet',
				method: 'POST',
				body: params,
				// body: { ...params, perPage: DEFAULTS.videosPerPage },
			}),
		}),
	}),
});

export const { useTextByIdQuery, useChannelsQuery, useGetVideosQuery, useVideoInfoQuery, useGetVideosFacetQuery } =
	tvApi;
