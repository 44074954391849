import { ISelectOption } from '../interfaces/common';

interface ISelectOptionsProps {
	options: ISelectOption[];
	valuePattern?: 'index' | 'value';
}
const SelectOptions = ({ options, valuePattern = 'index' }: ISelectOptionsProps) => {
	return (
		<>
			{options.map(({ label, value }, index) => (
				<option key={index} value={valuePattern === 'index' ? index : value || index}>
					{label}
				</option>
			))}
		</>
	);
};

export default SelectOptions;
