import { useEffect } from 'react';
import styled from 'styled-components';
import tvImg from '../img/tv-app.webp';
import LoginButton from './LoginButton';

const SplashScreen = () => {
	useEffect(() => {
		const body = document.querySelector('body');
		if (!body) return;

		body.classList.add('not-auth');

		return () => body.classList.remove('not-auth');
	}, []);

	return (
		<SplashContainer className="d-flex flex-column gap-3 align-items-center m-auto">
			<LoginButton large className="z-index-1" />
			<TvImage src={tvImg} />
			{/* <SplashThumbnail /> */}
		</SplashContainer>
	);
};

export default SplashScreen;

const SplashContainer = styled.div`
	padding-bottom: 90px;
`;
const TvImage = styled.img`
	height: 33vmin;
	width: auto;
	filter: drop-shadow(0 0 5px white);
`;
