import React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

interface IQueryLinkProps extends LinkProps {}
const QueryLink = ({ to, ...props }: IQueryLinkProps) => {
	const location = useLocation();
	return <Link to={to + location.search} {...props} />;
};

export default QueryLink;
